
.tncbox {
  max-width: 80%;
  margin-left: 10%;
  text-align: justify;
  .tnctexts {
    text-align: justify !important;
  }
  .tnclistsitem {
    margin-left: 1.5%;
    text-align: justify;
  }

  .innertnclistsitem {
    margin-left: 2%;
    text-align: justify;
  }
  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }
}
